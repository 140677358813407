import request from '@/utils/request.js'

export default {
  // 分页
  businessExpendPage: (data) => {
    return request({
      url: '/business/incomeExpendInfo/page',
      data,
      method: 'post'
    })
  },
  // 保存
  businessExpendSave: (data) => {
    return request({
      url: '/business/incomeExpendInfo/save',
      data,
      method: 'post'
    })
  },
  // 删除
  businessExpendDel: (id) => {
    return request({
      url: '/business/incomeExpendInfo/del/' + id,
      method: 'post'
    })
  },
  // 根据id查询
  businessExpendGetById: (id) => {
    return request({
      url: '/business/incomeExpendInfo/getById/' + id,
      method: 'post'
    })
  },

  // 收入支出统计
  incomeExpendCount: (data) => {
    return request({
      url: '/business/incomeExpendInfo/incomeExpendCount',
      data,
      method: 'post'
    })
  }
}
