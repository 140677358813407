import request from '@/utils/request.js'
export default {
  // 保存
  expendItemSave: (data) => {
    return request({
      url: '/business/expendItem/save',
      data,
      method: 'post'
    })
  },
  // 分页
  expendItemPage: (data) => {
    return request({
      url: '/business/expendItem/page',
      data,
      method: 'post'
    })
  }
}
