<template>
  <div class="spendingGm">
    <div class="spendingGm__left">
      <div class="spendingGm__left--heda">
        <el-button type="danger">批量删除</el-button>
      </div>
      <div class="spendingGm__left--table">
        <el-table @selection-change='selectionChange' :data="tableData" stripe style="width: 100%" height="100%">
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            prop="responsiblePerson"
            label="经办人"
          ></el-table-column>
          <el-table-column
            prop="incomeExpendMoney"
            label="金额"
          ></el-table-column>
          <el-table-column prop="sourcePurpose" label="用途"></el-table-column>
          <el-table-column label="时间">
            <template slot-scope="scope">
              <div>
                {{ setTime(scope.row.incomeExpendTime) }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="spendingGm__left--pagination">
        <el-pagination
          :page-size="pageData.pageSize"
          :current-page.sync="pageData.pageNum"
          @current-change="getPage"
          background
          layout="prev, pager, next"
          :total="pageData.total"
        >
        </el-pagination>
      </div>
    </div>
    <div class="spendingGm__right">
      <spendingGmNew />
    </div>
  </div>
</template>
<script>
import businessExpend from '@/service/businessExpend.js'
import spendingGmNew from '@/views/spendingGm/components/spendingGmNew/index.vue'
import { mapState } from 'vuex'
export default {
  components: {
    spendingGmNew
  },
  data () {
    return {
      loading: false, // 是否加载中
      tableData: [],
      selectiIdLs: [],
      pageData: {
        pageNum: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo,
      message: (store) => store.login.message
    })
  },
  mounted () {
    this.getPage()
  },
  methods: {
    async getPage () {
      this.loading = true
      const formData = {
        businessId: this.shopInfo.id,
        pageSize: this.pageData.pageSize,
        pageNum: this.pageData.pageNum,
        incomeExpendType: 2
      }
      const res = await businessExpend.businessExpendPage(formData)
      this.tableData = res.data.list
      this.pageData.total = res.data.total
      this.loading = false
    },
    setTime (time) {
      return this.$dayjs(time).format('YYYY-MM-DD')
    },
    selectionChange (e) {
      this.selectiIdLs = e.map(item => item.id)
      console.log(this.selectiIdLs)
    }
  }
}
</script>

<style lang="scss" scoped>
.spendingGm {
  height: 100%;
  display: flex;
  &__left {
    height: 100%;
    background: #ffffff;
    border-radius: 24px;
    flex: 1;
    min-width: 0px;
    padding: 32px 43px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    &--heda {
      margin-bottom: 30px;
    }
    &--table {
      flex: 1;
      min-height: 0px;
    }
    &--pagination {
      text-align: right;
      margin-top: 20px;
    }
  }
  &__right {
    width: 671px;
    height: 100%;
    margin-left: 56px;
  }
}
::v-deep .el-tabs__nav-next {
  line-height: 87px !important;
}
::v-deep .el-tabs__nav-prev {
  line-height: 87px !important;
}
</style>
