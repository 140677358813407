import request from '@/utils/request.js'

export default {
  // 商桌区域
  listByBusinessId: (id) => {
    return request({
      url: '/business/businessArea/listByBusinessId/' + id,
      method: 'post'
    })
  },
  // 商桌区域新增
  businessTableArea: (data) => {
    return request({
      url: '/business/businessArea/save',
      data,
      method: 'post'
    })
  },
  // 商桌区域删除
  businessTableAreaDel: (id) => {
    return request({
      url: '/business/businessArea/del/' + id,
      method: 'post'
    })
  },
  // 商桌区域批量保存
  businessTableAreaSaveBatch: (data) => {
    return request({
      url: '/business/businessArea/saveBatch',
      data,
      method: 'post'
    })
  },
  // 查询区域
  businessAreaList: (data) => {
    return request({
      url: '/business/businessArea/businessAreaList',
      data,
      method: 'post'
    })
  },
  // 根据商家id查询聚合
  listFoodInfoVoByBusinessId: (id) => {
    return request({
      url: '/business/foodInfo/listFoodInfoVoByBusinessId/' + id,
      method: 'post'
    })
  }
}
