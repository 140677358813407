import request from '@/utils/request.js'

export default {
  // 商桌区域
  businessTableInfoPage: (data) => {
    return request({
      url: '/business/businessTableInfo/page',
      data,
      method: 'post'
    })
  },
  // 商桌区域
  businessTableInfoSave: (data) => {
    return request({
      url: '/business/businessTableInfo/save',
      data,
      method: 'post'
    })
  },
  // 商桌根据id查询
  businessTableInfoGetById: (id) => {
    return request({
      url: '/business/businessTableInfo/getById/' + id,
      method: 'post'
    })
  },
  // 商桌根据id删除
  businessTableInfoDel: (id) => {
    return request({
      url: '/business/businessTableInfo/del/' + id,
      method: 'post'
    })
  },
  // 商桌绑定
  businessTableBind: (data) => {
    return request({
      data,
      url: '/business/businessTableInfo/businessTableBind',
      method: 'post'
    })
  },
  // 商桌解绑
  businessTableUnbind: (id) => {
    return request({
      url: '/business/businessTableInfo/businessTableUnbind/' + id,
      method: 'post'
    })
  },
  // 查询商桌信息(扫码点餐时调用)
  getBusinessTable: (data) => {
    return request({
      url: '/customer/businessTableInfo/getBusinessTable',
      data,
      method: 'post'
    })
  }
}
