<template>
  <div class="spendingReason">
    <div class="spendingReason__head">选择用途</div>
    <div class="spendingReason__body">
      <el-tag
        @close="businessspendingReasonClose(item.id)"
        v-for="item in list"
        :key="item.id"
        :type="businessAreaId === item.id ? 'success' : 'info'"
      >
        {{ item.itemName }}
      </el-tag>
      <span
        class="spendingReason__body--add"
        @click="businessspendingReasonLongpress({})"
        >新建用途</span
      >
    </div>
  </div>
</template>
<script>
import expendItem from '@/service/expendItem.js'
import { mapState } from 'vuex'
export default {
  props: {
    businessAreaId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: []
    }
  },
  computed: {
    ...mapState({
      shopInfo: (store) => store.login.shopInfo
    })
  },
  created () {
    this.getList()
  },
  methods: {
    // 获取区域数据
    async getList () {
      const res = await expendItem.expendItemPage({
        pageNum: 1,
        pageSize: 2000,
        businessId: this.shopInfo.id
      })
      this.list = res.data.list
    },
    // 修改/新增区域
    businessspendingReasonLongpress (item) {
      this.$prompt('请输入用途', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValidator: (value) => {
          if (value) {
            return true
          }
          return '请输入分组名称'
        }
      }).then(async ({ value }) => {
        const res = await expendItem.expendItemSave({
          businessId: this.shopInfo.id,
          itemName: value
        })
        if (res.success) {
          this.getList()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.spendingReason {
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: 264px;
  padding: 25px 25px;
  box-sizing: border-box;
  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    font-size: 20px;
    margin-bottom: 24px;
  }
  &__body {
    &--add {
      border: 1px dashed #000000;
      border-radius: 6px;
      width: 65px;
      height: 27px;
      color: #000000;
      font-size: 12px;
      text-align: center;
      line-height: 27px;
      display: inline-block;
      cursor: pointer;
    }
  }
}
::v-deep .el-tag {
  cursor: pointer;
  margin-bottom: 10px;
}
.tableArea__body--tab + .tableArea__body--tab {
  margin-left: 10px;
}
::v-deep.el-tag {
  margin-right: 30px;
}
</style>
