
class Barcode {
  constructor ({ successCD }) {
    this.barcode = ''
    this.successCD = successCD
    this.currentWebview = ''
    this.createBarcode()
  }

  onmarked (type, result) {
    this.successCD(result)
    // this.closeBarcodeComponent()
  }

  // 创建扫码框架
  createBarcode () {
    if (!this.barcode) {
      // eslint-disable-next-line no-undef
      this.barcode = plus.barcode.create('barcode', [plus.barcode.QR], {
        top: '0px',
        right: '0px',
        width: '100%',
        height: '100%',
        position: 'static'
      })
      this.barcode.onmarked = (type, result) => this.onmarked(type, result)
      // eslint-disable-next-line no-undef
      this.currentWebview = plus.webview.create('', 'currentWebview')
      this.currentWebview.append(this.barcode)
      this.createView(this.currentWebview)
      console.log()
    }
    this.barcode.start()
  }

  //  增加取消按钮
  createView (currentWebview) {
    // eslint-disable-next-line no-undef
    const backVew = new plus.nativeObj.View('backVew', {
      bottom: '10%',
      left: '30%',
      width: '40%',
      height: '44px'
    },
      [{
        tag: 'font',
        id: 'font',
        text: '取消扫码',
        textStyles: {
          size: '30px',
          color: '#ffffff'
        }
}
      ])
    backVew.addEventListener('click', () => {
      this.closeBarcodeComponent()
    }, false)
    backVew.show()
    currentWebview.append(backVew)
    currentWebview.show()
  }

  // 清除扫码
  closeBarcodeComponent () {
    if (!this.barcode) {
      // eslint-disable-next-line no-undef
      //  plus.webview.close(this.currentWebview)
      return
    }

    // eslint-disable-next-line no-undef
    plus.webview.close(this.currentWebview)
    this.barcode.cancel()
    this.barcode.close()
    this.barcode = null
  }
}

export default Barcode
